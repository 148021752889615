@use "base" as *;
@use "default";
@use "mouse";

body {
  color: #fff;
}

.hero {
  min-height: 100vh;
  padding: 2em 1em;

  // background: $bg-gray1;
  // 576px window width and more
  @include sm {
  }

  // 768px window width and more
  @include md {
    // -webkit-clip-path: polygon(0 0, 100% 0, 100% 83%, 0% 100%);
    // clip-path: polygon(0 0, 100% 0, 100% 83%, 0% 100%);
  }

  // 992px window width and more
  @include lg {
  }

  // 1200px window width and more
  @include xl {
  }
}

.top-nav {
  margin-bottom: 10em;

  @include md {
    margin-bottom: 12em;
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    @include md {
      gap: 70px;
    }

    li {
      &:hover {
        transform: translateY(-2px);
      }

      a {
        i {
          font-size: 30px;
          color: #fff;

          &:hover {
            @include text-gradient;
          }

          @include md {
            font-size: 42px;
          }
        }

        img {
          width: 30px;
          max-width: none;

          @include md {
            width: 40px;
          }

          &:hover {
            @include text-gradient;
          }
        }
      }

      // img {
      //   width: 40px;
      // }
    }
  }
}

.hero-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
  transition: 1s;

  h1 {
    font-weight: 700;
    font-size: 2.5rem;

    span {
      @include text-gradient;
    }

    @include md {
      font-size: 3.5rem;
    }
  }

  .hero-nav {
    display: flex;
    justify-content: center;
    align-items: center;

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      gap: 20px;
    }
  }
}

.skills {
  // background: $bg-gradient-dark;
  padding: 3em 0.8em;
  color: white;
  min-height: 100vh;

  h2 {
    @include heading;

    @include md {
      font-size: 3rem;
      margin-bottom: 0.2em;
    }
  }

  @include md {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 83%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 83%, 0% 100%);
    padding-bottom: 6em;
  }
}

.skills-sub-container {
  h3 {
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 1.5em;

    @include md {
      font-size: 1.8rem;
      margin-bottom: 0.8em;
    }
  }

  @include md {
    max-width: 942px;
    margin-inline: auto;
  }

  .skills-sub-container_all {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
    margin-bottom: 1em;
  }

  .each-skill {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    img {
      width: 50px;
    }

    span {
      font-size: 1rem;

      @include md {
        font-size: 1.2rem;
      }
    }
  }
}

.projects {
  // background: $bg-gray1;
  padding-top: 1em;

  h2 {
    @include heading;

    @include md {
      font-size: 3rem;
    }
  }

  @include md {
    margin-inline: auto;
    max-width: 1200px;
  }
}

.projects-container {
  padding: 0 1em;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;

  @include md {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    padding: 0;
  }
  @include xl {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
}

.each-project {
  position: relative;
  max-width: 350px;
  height: 164px;
  background-color: transparent;
  margin: 40px 0px;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in-out;
  border-radius: 15px;
  border: 1px solid #fff;

  .content {
    position: relative;
    top: -120px;
    padding: 10px 15px;
    color: #111;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in-out;
  }

  @include lg{
    height: 200px;
  }
  @media (hover: hover) {
    &:hover {
      height: 240px;
    }
    &:hover .content {
      margin-top: 60px;
      visibility: visible;
      opacity: 1;
      transition-delay: 0.2s;
      color: #fff;
      font-weight: 500;
      span {
        font-family: "Courier New", Courier, monospace;
      }
    }
  }

  @media (hover: none) {
    height: 240px;
    .content {
      margin-top: 60px;
      visibility: visible;
      opacity: 1;
      transition-delay: 0.2s;
      color: #fff;
      font-weight: 500;
      span {
        font-family: "Courier New", Courier, monospace;
      }
    }
  }

  .image {
    position: relative;
    width: 350px;
    height: 350px;
    top: -35%;
    left: 7px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    z-index: 1;
    img {
      max-width: 100%;
      border-radius: 15px;
    }
  }
}

.about {
  // background: $bg-gradient-dark;
  min-height: 100vh;
  color: $light-color;
  padding: 1em;
  margin-bottom: 2em;

  @include md {
    -webkit-clip-path: polygon(0 11%, 100% 0, 100% 85%, 0% 100%);
    clip-path: polygon(0 11%, 100% 0, 100% 85%, 0% 100%);
    padding-block: 6em;
  }

  h2 {
    @include heading;
    margin-bottom: 0.5em;

    @include md {
      font-size: 3rem;
    }
  }

  .cta-opposite {
    background: $bg-gray1;
    color: $dark-color;
    font-weight: 700;
    margin-inline: auto;
    max-width: min-content;
  }
}

.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-bottom: 0.8em;

  @include md {
    margin-bottom: 1.5em;
    margin-inline: auto;
    max-width: 1000px;
  }

  @include lg {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 3em;
  }

  img {
    width: 340px;
  }

  ul {
    list-style: none;
  }

  li {
    font-size: 1.3rem;
    padding-bottom: 0.5em;

    img {
      width: 28px;
      display: inline;
    }

    @include md {
      font-size: 1.4rem;
    }
  }
}

.contact {
  // background: $bg-gray1;
  min-height: 100vh;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  margin-bottom: 4em;

  p {
    font-size: 1.1rem;
    padding: 0.8rem;
    text-align: center;

    a {
      text-decoration: underline;
      color: #fff;
    }
  }

  h2 {
    @include heading;

    span {
      @include text-gradient;
    }

    margin-bottom: 0.6em;

    @include md {
      font-size: 3rem;
    }
  }

  .cta {
    width: min-content;
    margin: 0 auto;
  }
}

.contact-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  input {
    width: 80%;
    font-size: 1.2rem;
    padding: 0.6em 1em;
    outline: 0;
    border: 0.5px solid #fff;
    background: transparent;
    color: #fff;
  }

  textarea {
    color: #fff;
    width: 80%;
    font-size: 1.2rem;
    padding: 0.6em 1em;
    resize: vertical;
    height: 270px;
    outline: 0;
    border: 0.5px solid #fff;
    background: transparent;
    margin-bottom: 2em;
  }

  .submit-btn {
    outline: 0;
    border: 0;
    cursor: pointer;
  }
}

footer {
  // background-color: $dark-color;
  height: 30%;

  // display: flex;
  // justify-content: center;
  // align-items: center;
  div {
    div {
      text-align: center;
      margin-bottom: 2em;

      i {
        font-size: 40px;
        color: $light-color;

        &:hover {
          transform: translateY(-2px);
          @include text-gradient;
          cursor: pointer;
        }

        @include md {
          font-size: 42px;
        }
      }
    }
  }
}

.bottom-nav {
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    @include md {
      gap: 70px;
    }

    li {
      &:hover {
        transform: translateY(-2px);
      }

      a {
        i {
          font-size: 30px;
          color: $light-color;

          &:hover {
            @include text-gradient;
          }

          @include md {
            font-size: 42px;
          }
        }

        img {
          width: 30px;
          max-width: none;

          @include md {
            width: 40px;
          }

          &:hover {
            @include text-gradient;
          }
        }
      }
    }
  }
}

.credits {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  font-size: 1.3rem;
  font-weight: 500;

  span {
    display: block;
    margin-top: 7.5px;
  }

  img {
    width: 40px;
  }
}
