@use "breakpoints";

$ff-primary: "Montserrat", sans-serif;

$hue: 246;
$hue-complimentary1: 276; //+30
$hue-complimentary2: 306; //+60
$saturation: 95;
$lightness: 50;

$dark-color: hsl(($hue), 100%, 9%); //	#05002e
$light-color: hsl(($hue), 95%, 98%); //#f6f5ff
$base: hsl(($hue), 95%, 50%); //	#1f06f9
$complimentary1: hsl(($hue-complimentary1), 95%, 50%); //#9806f9
$complimentary2: hsl(($hue-complimentary2), 95%, 50%); //#f906e0

$bg-gradient: linear-gradient(
  to right bottom,
  hsl(($hue), 95%, 99%),
  hsl(($hue), 95%, 84%)
); //#fbfaff to 	#b7affd

$bg-gradient-dark: linear-gradient(to right bottom, #9806f9, #9c16f7, #9f21f5, #a229f4, #a530f2);

$bg-gray1: hsl(240, 5%, 96%);
$bg-gray2: hsl(0, 0%, 98%);

$breakpoint-tablet: 768px;

@mixin text-gradient {
  background-image: linear-gradient(45deg, ($base) 25%, ($complimentary2));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
@mixin heading {
  text-align: center;
  font-weight: 700;
  font-size: 2.5rem;
  margin-bottom: 1.7em;
  text-transform: uppercase;
}

// Small devices
@mixin sm {
  @media (min-width: #{breakpoints.$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{breakpoints.$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{breakpoints.$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{breakpoints.$screen-xl-min}) {
    @content;
  }
}

.cta {
  display: flex;
  padding: 0.8em 1em;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  background: linear-gradient(135deg, ($complimentary1) 25%, ($complimentary2));
  // @include text-gradient;
  transition: 1s;
  box-shadow: 6px 6px 0 black;
  transform: skewX(-15deg);
  &:focus {
    outline: none;
  }
  &:hover {
    transition: 0.5s;
    box-shadow: 10px 10px 0 #fbc638;
  }

  span {
    transform: skewX(15deg);
    
  }

  span:nth-child(2) {
    transition: 0.5s;
    margin-right: 0px;
  }
  &:hover span:nth-child(2) {
    transition: 0.5s;
    margin-right: 30px;
  }

  @include md {
    font-size: 1.2rem;
}
}

