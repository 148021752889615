.cta {
  text-transform: uppercase;
  color: #fff;
  background: linear-gradient(135deg, #9806f9 25%, #f906e0);
  padding: .8em 1em;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 1s;
  display: flex;
  -webkit-transform: skewX(-15deg);
  transform: skewX(-15deg);
  box-shadow: 6px 6px #000;
}

.cta:focus {
  outline: none;
}

.cta:hover {
  transition: all .5s;
  box-shadow: 10px 10px #fbc638;
}

.cta span {
  -webkit-transform: skewX(15deg);
  transform: skewX(15deg);
}

.cta span:nth-child(2) {
  margin-right: 0;
  transition: all .5s;
}

.cta:hover span:nth-child(2) {
  margin-right: 30px;
  transition: all .5s;
}

@media (min-width: 768px) {
  .cta {
    font-size: 1.2rem;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  font: inherit;
  margin: 0;
  padding: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

html, body {
  scroll-behavior: smooth;
  height: 100%;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
}

body {
  text-rendering: optimizespeed;
  line-height: 1.5;
}

a {
  text-decoration: none;
}

img, picture, svg {
  max-width: 100%;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    -webkit-animation-duration: .01ms !important;
    animation-duration: .01ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }
}

@media (hover: hover) {
  html {
    cursor: none;
  }

  svg#mouse-svg {
    display: none;
  }

  .Cursor {
    pointer-events: none;
    -webkit-transform-origin: center;
    transform-origin: center;
    mix-blend-mode: difference;
    z-index: 1000;
    -webkit-filter: url("#goo");
    filter: url("#goo");
    border-radius: 0;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
  }

  .Cursor span {
    -webkit-transform-origin: center;
    transform-origin: center;
    background-color: #fff;
    border-radius: 20px;
    width: 26px;
    height: 26px;
    display: block;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

body {
  color: #fff;
}

.hero {
  min-height: 100vh;
  padding: 2em 1em;
}

.top-nav {
  margin-bottom: 10em;
}

@media (min-width: 768px) {
  .top-nav {
    margin-bottom: 12em;
  }
}

.top-nav ul {
  justify-content: center;
  align-items: center;
  gap: 30px;
  display: flex;
}

@media (min-width: 768px) {
  .top-nav ul {
    gap: 70px;
  }
}

.top-nav ul li:hover {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

.top-nav ul li a i {
  color: #fff;
  font-size: 30px;
}

.top-nav ul li a i:hover {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(45deg, #1f06f9 25%, #f906e0);
  -webkit-background-clip: text;
  background-clip: text;
}

@media (min-width: 768px) {
  .top-nav ul li a i {
    font-size: 42px;
  }
}

.top-nav ul li a img {
  width: 30px;
  max-width: none;
}

@media (min-width: 768px) {
  .top-nav ul li a img {
    width: 40px;
  }
}

.top-nav ul li a img:hover {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(45deg, #1f06f9 25%, #f906e0);
  -webkit-background-clip: text;
  background-clip: text;
}

.hero-container {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  transition: all 1s;
  display: flex;
}

.hero-container h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

.hero-container h1 span {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(45deg, #1f06f9 25%, #f906e0);
  -webkit-background-clip: text;
  background-clip: text;
}

@media (min-width: 768px) {
  .hero-container h1 {
    font-size: 3.5rem;
  }
}

.hero-container .hero-nav {
  justify-content: center;
  align-items: center;
  display: flex;
}

.hero-container .hero-nav ul {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 100%;
  display: flex;
}

.skills {
  color: #fff;
  min-height: 100vh;
  padding: 3em .8em;
}

.skills h2 {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1.7em;
  font-size: 2.5rem;
  font-weight: 700;
}

@media (min-width: 768px) {
  .skills h2 {
    margin-bottom: .2em;
    font-size: 3rem;
  }

  .skills {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 83%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 83%, 0% 100%);
    padding-bottom: 6em;
  }
}

.skills-sub-container h3 {
  margin-bottom: 1.5em;
  font-size: 1.2rem;
  font-weight: 700;
}

@media (min-width: 768px) {
  .skills-sub-container h3 {
    margin-bottom: .8em;
    font-size: 1.8rem;
  }

  .skills-sub-container {
    max-width: 942px;
    margin-left: auto;
    margin-right: auto;
  }
}

.skills-sub-container .skills-sub-container_all {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-bottom: 1em;
  display: flex;
}

.skills-sub-container .each-skill {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.skills-sub-container .each-skill img {
  width: 50px;
}

.skills-sub-container .each-skill span {
  font-size: 1rem;
}

@media (min-width: 768px) {
  .skills-sub-container .each-skill span {
    font-size: 1.2rem;
  }
}

.projects {
  padding-top: 1em;
}

.projects h2 {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1.7em;
  font-size: 2.5rem;
  font-weight: 700;
}

@media (min-width: 768px) {
  .projects h2 {
    font-size: 3rem;
  }

  .projects {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}

.projects-container {
  grid-template-columns: 1fr;
  justify-items: center;
  padding: 0 1em;
  display: grid;
}

@media (min-width: 768px) {
  .projects-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    padding: 0;
  }
}

@media (min-width: 1200px) {
  .projects-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
}

.each-project {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #fff;
  border-radius: 15px;
  flex-direction: column;
  max-width: 350px;
  height: 164px;
  margin: 40px 0;
  padding: 20px 15px;
  transition: all .3s ease-in-out;
  display: flex;
  position: relative;
  box-shadow: 0 5px 20px rgba(0, 0, 0, .5);
}

.each-project .content {
  color: #111;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  padding: 10px 15px;
  transition: all .3s ease-in-out;
  position: relative;
  top: -120px;
}

@media (min-width: 992px) {
  .each-project {
    height: 200px;
  }
}

@media (hover: hover) {
  .each-project:hover {
    height: 240px;
  }

  .each-project:hover .content {
    visibility: visible;
    opacity: 1;
    color: #fff;
    margin-top: 60px;
    font-weight: 500;
    transition-delay: .2s;
  }

  .each-project:hover .content span {
    font-family: Courier New, Courier, monospace;
  }
}

@media (hover: none) {
  .each-project {
    height: 240px;
  }

  .each-project .content {
    visibility: visible;
    opacity: 1;
    color: #fff;
    margin-top: 60px;
    font-weight: 500;
    transition-delay: .2s;
  }

  .each-project .content span {
    font-family: Courier New, Courier, monospace;
  }
}

.each-project .image {
  z-index: 1;
  width: 350px;
  height: 350px;
  position: relative;
  top: -35%;
  left: 7px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, .2);
}

.each-project .image img {
  border-radius: 15px;
  max-width: 100%;
}

.about {
  color: #f6f5ff;
  min-height: 100vh;
  margin-bottom: 2em;
  padding: 1em;
}

@media (min-width: 768px) {
  .about {
    -webkit-clip-path: polygon(0 11%, 100% 0, 100% 85%, 0% 100%);
    clip-path: polygon(0 11%, 100% 0, 100% 85%, 0% 100%);
    padding-top: 6em;
    padding-bottom: 6em;
  }
}

.about h2 {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: .5em;
  font-size: 2.5rem;
  font-weight: 700;
}

@media (min-width: 768px) {
  .about h2 {
    font-size: 3rem;
  }
}

.about .cta-opposite {
  color: #05002e;
  background: #f4f4f5;
  max-width: -webkit-min-content;
  max-width: min-content;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
}

.about-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-bottom: .8em;
  display: flex;
}

@media (min-width: 768px) {
  .about-container {
    margin-bottom: 1.5em;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .about-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 3em;
  }
}

.about-container img {
  width: 340px;
}

.about-container ul {
  list-style: none;
}

.about-container li {
  padding-bottom: .5em;
  font-size: 1.3rem;
}

.about-container li img {
  width: 28px;
  display: inline;
}

@media (min-width: 768px) {
  .about-container li {
    font-size: 1.4rem;
  }
}

.contact {
  min-height: 100vh;
  margin-bottom: 4em;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.contact p {
  text-align: center;
  padding: .8rem;
  font-size: 1.1rem;
}

.contact p a {
  color: #fff;
  text-decoration: underline;
}

.contact h2 {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: .6em;
  font-size: 2.5rem;
  font-weight: 700;
}

.contact h2 span {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(45deg, #1f06f9 25%, #f906e0);
  -webkit-background-clip: text;
  background-clip: text;
}

@media (min-width: 768px) {
  .contact h2 {
    font-size: 3rem;
  }
}

.contact .cta {
  width: -webkit-min-content;
  width: min-content;
  margin: 0 auto;
}

.contact-container form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
}

.contact-container form input {
  color: #fff;
  background: none;
  border: .5px solid #fff;
  outline: 0;
  width: 80%;
  padding: .6em 1em;
  font-size: 1.2rem;
}

.contact-container form textarea {
  color: #fff;
  resize: vertical;
  background: none;
  border: .5px solid #fff;
  outline: 0;
  width: 80%;
  height: 270px;
  margin-bottom: 2em;
  padding: .6em 1em;
  font-size: 1.2rem;
}

.contact-container form .submit-btn {
  cursor: pointer;
  border: 0;
  outline: 0;
}

footer {
  height: 30%;
}

footer div div {
  text-align: center;
  margin-bottom: 2em;
}

footer div div i {
  color: #f6f5ff;
  font-size: 40px;
}

footer div div i:hover {
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  background-image: linear-gradient(45deg, #1f06f9 25%, #f906e0);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

@media (min-width: 768px) {
  footer div div i {
    font-size: 42px;
  }
}

.bottom-nav ul {
  justify-content: center;
  align-items: center;
  gap: 30px;
  display: flex;
}

@media (min-width: 768px) {
  .bottom-nav ul {
    gap: 70px;
  }
}

.bottom-nav ul li:hover {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

.bottom-nav ul li a i {
  color: #f6f5ff;
  font-size: 30px;
}

.bottom-nav ul li a i:hover {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(45deg, #1f06f9 25%, #f906e0);
  -webkit-background-clip: text;
  background-clip: text;
}

@media (min-width: 768px) {
  .bottom-nav ul li a i {
    font-size: 42px;
  }
}

.bottom-nav ul li a img {
  width: 30px;
  max-width: none;
}

@media (min-width: 768px) {
  .bottom-nav ul li a img {
    width: 40px;
  }
}

.bottom-nav ul li a img:hover {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(45deg, #1f06f9 25%, #f906e0);
  -webkit-background-clip: text;
  background-clip: text;
}

.credits {
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  font-size: 1.3rem;
  font-weight: 500;
  display: flex;
}

.credits span {
  margin-top: 7.5px;
  display: block;
}

.credits img {
  width: 40px;
}

/*# sourceMappingURL=index.c4be2674.css.map */
