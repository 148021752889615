
html {
  @media (hover: hover) {
    cursor: none;
  }
}

svg#mouse-svg {
  @media (hover: hover) {
    display: none;
  }
}

.Cursor {
  @media (hover: hover) {
    pointer-events: none;
    position: fixed;
    display: block;
    border-radius: 0;
    transform-origin: center center;
    mix-blend-mode: difference;
    top: 0;
    left: 0;
    z-index: 1000;
    filter: url("#goo");
    span {
      position: absolute;
      display: block;
      width: 26px;
      height: 26px;
      border-radius: 20px;
      background-color: #ffffff;
      transform-origin: center center;
      transform: translate(-50%, -50%);
    }
  }
}
